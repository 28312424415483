import React from 'react'
import { CardType } from '../../../utils'
import { ReactComponent as AmexIcon } from './assets/amex.svg'
import { ReactComponent as DiscoverIcon, ReactComponent as GenericIcon } from './assets/discover.svg'
import { ReactComponent as MasterCardIcon } from './assets/mastercard.svg'
import { ReactComponent as VisaIcon } from './assets/visa.svg'
import styles from './CardIcon.module.css'

interface CardIconProps {
  cardType: CardType
}

const CardIcon: React.FC<CardIconProps> = ({ cardType }) => {
  let Component = GenericIcon

  switch (cardType) {
    case 'Visa':
      Component = VisaIcon
      break
    case 'Mastercard':
      Component = MasterCardIcon
      break
    case 'American Express':
      Component = AmexIcon
      break
    case 'Discover':
      Component = DiscoverIcon
      break
    default:
      Component = GenericIcon
      break
  }

  return <Component className={styles.icon} />
}

export default CardIcon
